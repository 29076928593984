import '../styles/index.scss';

let slides = document.querySelector('#slides');
let menu = document.querySelector('#menu');

const VIDEO_SLIDE_NUMBER_1 = 3;
const VIDEO_SLIDE_NUMBER_2 = 5;
const VIDEO_SLIDE_NUMBER_3 = 8;

if (slides && menu) {
    let slidesCount = slides.querySelectorAll('.slide').length;

    let nextSlideButton = document.querySelector('#slides-next');
    if (nextSlideButton) {
        nextSlideButton.addEventListener('click', function (e) {
            let activeSlide = slides.dataset.activeSlide;
            if (activeSlide < slidesCount - 1) {
                slides.classList.remove('active-' + activeSlide);
                activeSlide++;
                slides.classList.add('active-' + activeSlide);
                slides.dataset.activeSlide = activeSlide;

                slideEvent(activeSlide);
            }
        });
    }

    let prevSlideButton = document.querySelector('#slides-prev');
    if (prevSlideButton) {
        prevSlideButton.addEventListener('click', function (e) {
            let activeSlide = slides.dataset.activeSlide;
            if (activeSlide > 0) {
                slides.classList.remove('active-' + activeSlide);
                activeSlide--;
                slides.classList.add('active-' + activeSlide);
                slides.dataset.activeSlide = activeSlide;

                slideEvent(activeSlide);
            }
        });
    }

    let slideEvent = function (slideNumber) {
        let video1 = document.getElementById('video-1');

        if (video1) {
            if (slideNumber === VIDEO_SLIDE_NUMBER_1) {
                video1.pause();
                video1.currentTime = 0;
            } else {
                setTimeout(function () {
                    video1.pause();
                    video1.currentTime = 0;
                }, 1000);
            }
        }

        let video2 = document.getElementById('video-2');

        if (video2) {
            if (slideNumber === VIDEO_SLIDE_NUMBER_2) {
                video2.pause();
                video2.currentTime = 0;
            } else {
                setTimeout(function () {
                    video2.pause();
                    video2.currentTime = 0;
                }, 1000);
            }
        }


        let video3 = document.getElementById('video-3');

        if (video3) {
            if (slideNumber === VIDEO_SLIDE_NUMBER_3) {
                video3.pause();
                video3.currentTime = 0;
            } else {
                setTimeout(function () {
                    video3.pause();
                    video3.currentTime = 0;
                }, 1000);
            }
        }

        prevSlideButton.classList.toggle('is-hidden', slideNumber === 0);
        nextSlideButton.classList.toggle('is-hidden', slideNumber === slidesCount - 1);
        //menu.classList.toggle('is-hidden', slideNumber < 1000);
    };

    let setActiveSlide = function (slideNumber) {
        for (let i = 0; i <= slidesCount; i++) {
            slides.classList.toggle('active-' + i, slideNumber === i);
        }
        slides.dataset.activeSlide = slideNumber;
        slideEvent(slideNumber);
    };

    let menuItems = menu.querySelectorAll('a');
    menuItems.forEach(function (menuItem) {
        menuItem.addEventListener('click', function (e) {
            setTimeout(function () {
                setActiveSlide(e.target.dataset.slideNumber);
            }, 10);

            menuItems.forEach(function (menuItem) {
                menuItem.classList.remove('active');
            });
            e.target.classList.add('active');
        });
    });

    document.querySelector('#title-box').addEventListener('click', function (e) {
        setActiveSlide(0);
    });
}